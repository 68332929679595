<template>
  <!--  考试信息-->
  <div class="exercisesType">
    <div style="display: flex; justify-content: space-between">
      <div class="menu_select">
        <h4 style="padding: 10px">模块</h4>
        <a-menu style="width: 100%" mode="inline" v-model="menu">
          <a-menu-item
            v-for="(item, index) in menuList"
            :key="index"
            @click="leftChange(item.id)"
            >{{ item.name }}</a-menu-item
          >
        </a-menu>
      </div>
      <div class="InfoTable">
        <p
          style="
            padding: 10px 0px 2px 16px;
            font-weight: 550;
            font-size: 15px;
            color: black;
          "
        >
          首页推荐内容
        </p>
        <a-table
          :columns="columns"
          :data-source="messageList"
          :rowKey="(record) => record.id"
          :pagination="false"
          style="margin: 15px"
        >
          <span slot="sort" slot-scope="text, record, index">
            {{ (pageNo - 1) * pageSum + index + 1 }}
          </span>
          <span slot="type" slot-scope="text, record, index">
            <span v-if="record.contentType === 'message'">考试信息</span>
            <span v-else>考点精选</span>
          </span>
          <span slot="title11" slot-scope="text, record, index">
            {{ record.title }}
          </span>
          <span slot="operation" slot-scope="text, record, index">
            <a
              v-if="index !== 0"
              @click="move('up', record.id)"
              style="margin-right: 5px"
              >上移</a
            >
            <a
              @click="move('down', record.id)"
              style="margin-right: 5px"
              v-if="index !== messageList.length - 1"
              >下移</a
            >
            <a-popconfirm
              title="确定取消推荐?"
              ok-text="是"
              cancel-text="否"
              @confirm="del(record.id)"
              @cancel="cancel"
            >
              <a>取消推荐</a>
            </a-popconfirm>
          </span>
        </a-table>
        <div
          style="
            margin-top: 28px;
            margin-left: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
          "
        >
          <span
            style="
              font-size: medium;
              color: #929292;
              font-size: 14px;
              bottom: 3%;
            "
          >
            共{{ total }}条记录 第 {{ pageNo }} /
            {{ Math.ceil(total / pageSum) }} 页
          </span>
          <a-pagination
            style="float: right"
            show-quick-jumper
            show-size-changer
            :page-size.sync="pageSum"
            :total="total"
            v-model="pageNo"
            @change="pageChange"
            @showSizeChange="pageChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getMoKuaiData,
  getRecommendList,
  moveRecommendList,
  delRecommendList,
} from "@/service/titleExam_api";

export default {
  data() {
    return {
      total: 0,
      id: "",
      pageNo: 1,
      pageSum: 10,
      menuList: [],
      menu: [0],
      messageList: [],
      columns: [
        {
          title: "序号",
          width: "5%",
          scopedSlots: { customRender: "sort" },
        },
        {
          title: "标题",
          scopedSlots: { customRender: "title11" },
          width: "20%",
        },
        {
          title: "来源",
          scopedSlots: { customRender: "type" },
          width: "7%",
        },
        {
          title: "发表日期",
          dataIndex: "issueDate",
          width: "10%",
        },
        {
          title: "推荐时间",
          dataIndex: "createdTime",
          width: "10%",
        },
        {
          title: "操作",
          scopedSlots: { customRender: "operation" },
          width: "17%",
        },
      ],
    };
  },
  created() {},
  mounted() {
    this.getMenu();
  },
  methods: {
    async getMenu() {
      let data = {
        page: 1,
        pageSize: 999,
      };
      const res = await getMoKuaiData(data);
      if (res.code == 200) {
        this.menuList = res.data;
        this.load();
      }
    },
    // 默认加载第一个模块内容
    async load() {
      // console.log("第一个", this.menuList);
      this.id = this.menuList[0].id;
      let data = {
        examTypeId: this.menuList[0].id,
        pageNo: this.pageNo,
        pageSum: this.pageSum,
      };
      console.log(data);
      const res = await getRecommendList(data);
      if (res.code == 0) {
        this.messageList = res.data;
        this.total = res.count;
      }
    },
    // 点击左侧菜单获取内容
    async leftChange(id) {
      this.id = id;
      let data = {
        examTypeId: this.id,
        pageNo: this.pageNo,
        pageSum: this.pageSum,
      };
      const res = await getRecommendList(data);
      if (res.code == 0) {
        this.messageList = res.data;
        this.total = res.count;
      }
    },
    // 移动
    async move(type, id) {
      const res = await moveRecommendList(id, type);
      if (res.code == 0) {
        this.$message.success("移动成功");
        this.leftChange(this.id);
      }
    },
    // 取消推荐
    async del(id) {
      const res = await delRecommendList(id);
      if (res.code == 0) {
        this.$message.success("取消成功");
        this.leftChange(this.id);
      }
    },
    cancel() {},
    // 分页查询
    pageChange(pageNo, pageSum) {
      this.pageNo = pageNo;
      this.pageSum = pageSum;
      this.leftChange(this.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.exercisesType {
  margin: -15px -15px;
  background-color: rgba(240, 242, 245, 1);

  .menu_select {
    background-color: white;
    width: 18%;
    min-height: 750px;
  }
  .InfoTable {
    background-color: white;
    width: 81%;
    min-height: 750px;
   
  }
}
</style>
